var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("机构注册审批")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "机构名称", prop: "orgName" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "110px",
                              height: "36px!important",
                              "line-height": "36px",
                            },
                            attrs: { placeholder: "机构名称" },
                            model: {
                              value: _vm.queryParams.orgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "orgName", $$v)
                              },
                              expression: "queryParams.orgName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请服务包", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: "服务包名称",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryParams.packId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "packId", $$v)
                                },
                                expression: "queryParams.packId",
                              },
                            },
                            _vm._l(_vm.serviceList, function (item) {
                              return _c("el-option", {
                                key: "serviceList" + item.packId,
                                attrs: {
                                  label: item.packName,
                                  value: item.packId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              type: "daterange",
                              "value-format": "yyyy-MM-dd",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.timeChange },
                            model: {
                              value: _vm.setDateRange,
                              callback: function ($$v) {
                                _vm.setDateRange = $$v
                              },
                              expression: "setDateRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "审批状态", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "100px" },
                              attrs: {
                                placeholder: "状态",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryParams.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "status", $$v)
                                },
                                expression: "queryParams.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: "statusOptions" + dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "oraTableData",
          attrs: {
            border: "",
            stripe: "",
            height: _vm.tableHeight,
            "header-cell-style": { "text-align": "center" },
            "cell-style": { "text-align": "center" },
            data: _vm.applyList,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "审批编号", prop: "applyId", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "机构名称",
              prop: "orgName",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请服务包",
              prop: "sysServicePackStr",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "审批状态", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.statusStr === "待审批"
                      ? _c("span", { staticClass: "dsp" }, [
                          _vm._v(_vm._s(scope.row.statusStr)),
                        ])
                      : scope.row.statusStr === "已通过"
                      ? _c("span", { staticClass: "ytg" }, [
                          _vm._v(_vm._s(scope.row.statusStr)),
                        ])
                      : scope.row.statusStr === "已拒绝"
                      ? _c("span", { staticClass: "yjj" }, [
                          _vm._v(_vm._s(scope.row.statusStr)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请时间",
              align: "center",
              prop: "createTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "审批时间",
              align: "center",
              prop: "createTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.processTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === "0"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleApply(scope.row)
                              },
                            },
                          },
                          [_vm._v("审批")]
                        )
                      : _vm._e(),
                    scope.row.status === "1"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.lookDatas(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    scope.row.status === "2"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.lookDataf(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.isResult === false
            ? _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-left-data.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
              ]),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "审批",
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "approvalContentDiv" },
            [
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构名称：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.orgName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人姓名：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("手机号：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactCell))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人电子邮箱：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactEmail))])]
                  ),
                ],
                1
              ),
              _vm.applyData.orgType !== "3"
                ? _c(
                    "el-row",
                    { staticClass: "approvalContent" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentLeft",
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 },
                        },
                        [_c("span", [_vm._v("医院等级：")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentRight",
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.applyData.historyLevelStr)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.halfFullAddress)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("详细地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.detailAddress))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("申请服务包：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.sysServicePackStr)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "adoptClass",
                      attrs: { type: "success" },
                      on: { click: _vm.adoptNext },
                    },
                    [_vm._v("通过")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "refuseClass",
                      attrs: { type: "danger" },
                      on: { click: _vm.refuseNext },
                    },
                    [_vm._v("拒绝")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "审批结果",
            visible: _vm.isSuccess,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isSuccess = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c("i", {
                    staticClass: "el-icon-success",
                    staticStyle: {
                      color: "#01C200",
                      "font-size": "30px",
                      "padding-right": "5px",
                    },
                  }),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "left",
                    color: "#333333",
                    "font-size": "16px",
                    "line-height": "30px",
                    "font-family": "PingFang SC",
                  },
                  attrs: { span: 12 },
                },
                [_c("span", [_vm._v("审批已通过！")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "approvalContentDiv" },
            [
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构名称：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.orgName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人姓名：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("手机号：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactCell))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人电子邮箱：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactEmail))])]
                  ),
                ],
                1
              ),
              _vm.applyData.orgType !== "3"
                ? _c(
                    "el-row",
                    { staticClass: "approvalContent" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentLeft",
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 },
                        },
                        [_c("span", [_vm._v("医院等级：")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentRight",
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.applyData.historyLevelStr)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.halfFullAddress)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("详细地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.detailAddress))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("申请服务包：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.sysServicePackStr)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("审批通过备注说明：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form_look_success",
                          attrs: {
                            model: _vm.formLabelAlign_look_success,
                            rules: _vm.rules_look_success,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请填写拒绝理由！",
                                  disabled: "disabled",
                                },
                                model: {
                                  value: _vm.formLabelAlign_look_success.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formLabelAlign_look_success,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formLabelAlign_look_success.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitLookSuccessForm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "审批结果",
            visible: _vm.isFail,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isFail = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c("i", {
                    staticClass: "el-icon-error",
                    staticStyle: {
                      color: "#FF3B30",
                      "font-size": "30px",
                      "padding-right": "5px",
                    },
                  }),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "left",
                    color: "#333333",
                    "font-size": "16px",
                    "line-height": "30px",
                    "font-family": "PingFang SC",
                  },
                  attrs: { span: 12 },
                },
                [_c("span", [_vm._v("审批已拒绝！")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "approvalContentDiv" },
            [
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构名称：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.orgName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人姓名：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactName))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("手机号：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactCell))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("联系人电子邮箱：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.contactEmail))])]
                  ),
                ],
                1
              ),
              _vm.applyData.orgType !== "3"
                ? _c(
                    "el-row",
                    { staticClass: "approvalContent" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentLeft",
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 },
                        },
                        [_c("span", [_vm._v("医院等级：")])]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "approvalContentRight",
                          staticStyle: { "text-align": "left" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.applyData.historyLevelStr)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("机构地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.halfFullAddress)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("详细地址：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.applyData.detailAddress))])]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("申请服务包：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.applyData.sysServicePackStr)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "approvalContent" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentLeft",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [_c("span", [_vm._v("拒绝理由：")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "approvalContentRight",
                      staticStyle: { "text-align": "left" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form_look_fail",
                          attrs: {
                            model: _vm.formLabelAlign_look_fail,
                            rules: _vm.rules_look_fail,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请填写拒绝理由！",
                                  disabled: "disabled",
                                },
                                model: {
                                  value: _vm.formLabelAlign_look_fail.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formLabelAlign_look_fail,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression: "formLabelAlign_look_fail.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitLookFailForm },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "审批结果",
            visible: _vm.isApprovalSuccess,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isApprovalSuccess = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.formLabelAlign, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批通过备注说明：", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写审批通过备注说明...",
                      "show-word-limit": "",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.formLabelAlign.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLabelAlign, "remark", $$v)
                      },
                      expression: "formLabelAlign.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitSuccessApplyForm },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "failClass",
                      on: { click: _vm.successCancel },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "审批结果",
            visible: _vm.isApprovalFail,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isApprovalFail = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form_fail",
              attrs: { model: _vm.formLabelAlign_fail, rules: _vm.rules_fail },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "拒绝理由：(将通过手机短信发送至用户)",
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写拒绝理由！",
                      "show-word-limit": "",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.formLabelAlign_fail.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formLabelAlign_fail, "remark", $$v)
                      },
                      expression: "formLabelAlign_fail.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitFailApplyForm },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    { staticClass: "failClass", on: { click: _vm.failCancel } },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }